.marquee {
    position: relative;
    overflow: hidden;
    font-family: 'Onest';
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    background-color: #760000;
    padding-top: 20px;
    margin-top: -20px;
    z-index: 0;
    z-index: 0;
  }
  
  .marquee__inner {
    display: flex;
  }
  
  .marquee__line {
    color: white;
    flex-shrink: 0;
    margin: 0;
    padding: 10px 15px;
    min-width: 100%;
    white-space: nowrap;
    animation-name: marqueeLine;
    animation-duration: 10s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }
  
  @keyframes marqueeLine {
    from {
      transform: translateX(0);
    }
    
    to {
      transform: translateX(-100%);
    }
  }