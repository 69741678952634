@import 'variables.scss';
@import 'normalize.scss';
@import 'wraps.scss';

html {
    background-color: #F8F8F8;
}
html, body {
    height: 100%;
    margin: 0;
  }

body {
    margin:0;
    padding:0;
    color:$color-text;

    font-family:$font-family;
    font-size:$font-size;
    letter-spacing:$letter-spacing;
    line-height:$line-height;
    text-align:left;
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale;

    &.no-scroll {
        overflow:hidden !important;
    }
}

* {
    box-sizing:border-box;

    &:before, &:after {
        box-sizing:border-box;

        @media print {
            box-shadow:none !important;
            text-shadow:none !important;
        }
    }

    &:focus {
        outline:none !important;
    }

    @media print {
        box-shadow:none !important;
        text-shadow:none !important;
    }
}

// Links
a {
    color:$color-link;
    cursor:pointer;
    text-decoration:underline;

    &:hover, &:focus {
        color:$color-link-hover;
        text-decoration:none;
    }
}

a, button, label, input {
    -webkit-tap-highlight-color:transparent;

    @media print {
        text-decoration:underline;
    }
}

button {
    cursor:pointer;

    &:disabled {
        cursor:auto;
    }
}

// Media
img, svg, canvas, audio, video, iframe {
    vertical-align:middle;
}

figure {
    margin:0;
}

// Inputs
input, button, select, textarea {
    font-family:inherit;
    font-size:inherit;
    line-height:inherit;
}

// Text & Headings
p, h1, h2, h3, h4, h5, h6, blockquote {
    margin:0;
    padding:0;

    font-weight:inherit;
    font-style:inherit;
    font-size:inherit;
    line-height:inherit;
    text-align:inherit;
    letter-spacing:inherit;

    @media print {
        page-break-after:avoid;
        orphans:3;
        widows:3;
    }
}

em, address {
    font-style:normal;
}

svg {
    display:inline-block;
    width:1em;
    height:1em;
    fill:currentColor;
    vertical-align:middle;
}
