// .am-view {
//     height:100%;
// }

.am-layout {
    // height:100vh;

    /* Smooth Scrollbar Approach */
    .scrollbar {
        &-track {
            right:3px;
            background:none;
            z-index:200;
        }

        &-thumb {
            &-y {
                background:mix($color-text, $color-base, 80%);

                &:before {
                    content:"";
                    display:block;
                    position:absolute;
                    top:0;
                    right:-3px;
                    left:-3px;
                    bottom:0;
                }

                &:hover {
                    background:$color-text;
                }
            }
        }
    }

    .scroll-content {
        height:100%;
        will-change:transform;
    }
}

.am-content {
    padding:40px 0;

    @media (min-width:$screen-sm-min) {
        padding:50px 0;
    }

    @media (min-width:$screen-lg-min) {
        padding:60px 0;
    }

    @media (min-width:$screen-xl-min) {
        padding:70px 0;
    }

    &.-cb {
        padding-bottom:0;
    }
}

.am-container {
    padding:0 20px;

    @media (min-width:$screen-sm-min) {
        padding:0 30px;
    }

    @media (min-width:$screen-md-min) {
        padding:0 40px;
    }

    @media (min-width:$screen-lg-min) {
        padding:0 60px;
    }

    @media (min-width:$screen-xl-min) {
        max-width:$screen-xl-min;
        margin:0 auto;
        padding:0 80px;
    }

    &.-lg {
        @media (min-width:$screen-xl-min) {
            max-width:100%;
        }
    }
}
